<template>
  <v-card flat>
    <v-card-text>
      <v-sheet class="mb-1" v-for="(line, index) in questions" :key="index">
        <QuestionSection v-if="line.tag === 'FormSection'" :value="index">
        </QuestionSection>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import QuestionSection from '@/modules/Forms-Online/components/QuestionSection.vue'
export default {
  name: 'Preview',
  components: {
    QuestionSection
  },
  props: {
    questions: Array
  }
}
</script>

<style scoped></style>
