<template>
  <v-card flat>
    <v-simple-table>
      <template v-slot:default>
        <thead class="primary">
          <tr>
            <th class="text-left" v-for="header in headers" :key="header.id">
              <span class="white--text"> {{ header.text }} </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in updatedItems" :key="item.id">
            <td v-for="(head, index) in headers" :key="index">
              <template v-if="index === 0 && item.user">
                <Avatar
                  :user="item.user"
                  :display="item.user.fullname"
                ></Avatar>
              </template>
              <template v-else-if="index === 0 && !item.user">
                <div class="d-flex align-center">
                  <v-avatar
                    size="45"
                    class="border-3 mr-3 border-color--primary"
                  >
                    <v-icon large>mdi-image</v-icon>
                  </v-avatar>
                  <strong>Unknown</strong>
                </div>
              </template>
              <AnswerDataViewer
                v-else-if="getData(head, item)"
                :item="getData(head, item)"
              ></AnswerDataViewer>
              <h1 class="text-center" v-else>-</h1>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions
      v-if="!noMoreData"
      class="d-flex align-center justify-center"
    >
      <v-btn
        @click="loadMore"
        depressed
        width="200"
        class="text-none"
        color="primary"
      >
        Load more
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VueTable from '@/common/VueTable/VueTable.vue'
import AnswerDataViewer from '../Answer/AnswerDataViewer.vue'
import _ from 'lodash'
export default {
  components: { VueTable, AnswerDataViewer },
  name: 'ResponseTableView',
  props: {
    noMoreData: { type: Boolean, default: false },
    items: Array,
    form: Object
  },
  computed: {
    headers() {
      if (!this.form && !this.form.questions.length) return []
      let headerList = [{ text: 'Responder', width: 200, sortable: false }]
      let sections = this.form.questions
        .filter((i) => i.tag === 'FormSection')
        .map((i) => i.value)
      let forms = [].concat.apply([], sections)
      headerList.push(
        ...forms
          .filter((i) => i.hasOwnProperty('value'))
          .map((i) => {
            return {
              id: i.id,
              text: i.label || 'Unknown Field',
              width: 140,
              sortable: false
            }
          })
      )
      return headerList
    },
    updatedItems() {
      return this.items.length
        ? this.items.map((i) => {
            let sections = i.data
              .filter((i) => i.tag === 'FormSection')
              .map((i) => i.value)
            let forms = [].concat.apply([], sections)
            return {
              id: i.id,
              user: i.user,
              data: forms.filter((y) => y.hasOwnProperty('value'))
            }
          })
        : []
    }
  },
  methods: {
    loadMore() {
      this.$emit('load-more')
      this.$event.$emit('btnloading_off')
    },
    getData(head, item) {
      if (!head.hasOwnProperty('id')) {
        return item.user
      }
      return item.data.find((i) => i.id === head.id) || null
    }
  }
}
</script>

<style></style>
