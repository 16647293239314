import axios from 'axios'
import request from '@/services/axios_instance'
import { mapGetters, mapMutations } from 'vuex'
//components
import Preview from '@/modules/Forms-Builder/components/Builder/Preview.vue'
import Response from '@/modules/Forms-Builder/components/Builder/Response.vue'
import ResponseTableView from '@/modules/Forms-Builder/components/Builder/ResponseTableView.vue'
import VueTable from '@/common/VueTable/VueTable.vue'

export default {
    name: 'FormResponses',
    components: {
        Preview,
        Response,
        ResponseTableView,
        VueTable
    },
    data: () => ({
        paths: [{
                text: 'Dashboard',
                disabled: false,
                route: { name: 'default-content' }
            },
            { text: 'Forms', disabled: false, route: { name: 'forms' } },
            { text: 'Response', disabled: true, router_name: null }
        ],
        form: null,
        loading: false,
        tab: 'responses',
        responses: [],
        next_url: null,
        activeResponder: null,
        loading_more: false,
        response_counts: 0,
        activeSlide: 'user-tab',
        staff_list: [],
        active_staff: null,
        active_staff_responses: [],
        user_table_headers: [{
                text: 'Name',
                value: 'fullname'
            },
            {
                text: 'Responses',
                value: 'response_counts',
                width: 200
            },
            {
                text: '',
                value: 'action',
                width: 100,
                sortable: false
            }
        ],
        staff_list_next_url: null,
        response_next_url: null,
        view: 'one-page'
    }),
    mounted() {
        if (this.has_reports) this.get_user_list()
        this.$event.$emit('path-change', this.paths)
    },
    computed: {
        ...mapGetters(['user']),
        responses_count: {
            get() {
                return this.response_counts || '0'
            },
            set(val) {
                this.response_counts = val
            }
        },
        has_reports() {
            return this.settings.form_reports_allowed.includes(this.user.company.id)
        },
        staffNoMoreData() {
            return !this.staff_list_next_url
        },
        responseNoMoreData() {
            return !this.response_next_url
        }
    },
    watch: {
        '$route.params.id': {
            handler: function(val) {
                if (val) this.getForm(val)
            },
            immediate: true
        },
        responses: {
            handler: function(val) {
                this.responses_count = val ? val.length : 0
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        ...mapMutations('forms', ['set_form']),
        refresh() {
            this.loading = true
            request
                .get(`api/forms/${this.$route.params.id}/responses`)
                .then(({ data }) => {
                    this.responses = data.data
                })
                .finally(() => (this.loading = false))
        },
        getForm(id) {
            this.loading = true
            axios
                .all([
                    request.get(`api/forms/${id}`),
                    request.get(`api/forms/${id}/responses`)
                ])
                .then(
                    axios.spread((res1, res2) => {
                        this.form = res1.data
                        this.set_form(this.form)
                        this.responses = res2.data.data
                        this.next_url = res2.data.next_page_url
                        this.activeResponder = this.responses[0] || null
                    })
                )
                .finally(() => (this.loading = false))
        },
        load_more_response() {
            this.loading_more = true
            request
                .get(this.next_url)
                .then(({ data }) => {
                    this.responses.push(...data.data)
                    this.next_url = data.next_page_url
                })
                .finally(() => (this.loading_more = false))
        },
        get_user_list() {
            this.loading = true
            request
                .get(`api/company/staffs/responses-count/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.staff_list = data.data
                    this.staff_list_next_url = data.next_page_url
                })
                .finally(() => (this.loading = false))
        },
        get_more_user_list() {
            this.loading = true
            request
                .get(this.staff_list_next_url)
                .then(({ data }) => {
                    this.staff_list.push(...data.data)
                    this.staff_list_next_url = data.next_page_url
                })
                .finally(() => {
                    this.loading = false
                    this.$event.$emit('btnloading_off')
                })
        },
        viewResponse(staff) {
            this.activeSlide = 'report-tab'
            if (!this.active_staff ||
                (this.active_staff && this.active_staff.id !== staff.id)
            ) {
                this.active_staff_responses = []
                this.active_staff = staff
                this.get_staff_responses()
            }
        },
        get_staff_responses() {
            this.loading = true
            request
                .get(
                    `api/company/staffs/${this.active_staff.id}/responses-list/${this.$route.params.id}`
                )
                .then(({ data }) => {
                    this.active_staff_responses = data.data
                    this.response_next_url = data.next_page_url
                })
                .finally(() => (this.loading = false))
        },
        get_more_staff_responses() {
            this.loading = true
            request
                .get(this.response_next_url)
                .then(({ data }) => {
                    this.active_staff_responses.push(...data.data)
                    this.response_next_url = data.next_page_url
                })
                .finally(() => (this.loading = false))
        },
        goBack() {
            if (this.$route.query.hasOwnProperty('redirect_uri')) {
                this.$router.push({ path: this.$route.query.redirect_uri }) //.catch(() => { })
            } else {
                this.$router.go(-1)
            }
        },
        toggleView() {
            this.view = this.view === 'one-page' ? 'table' : 'one-page'
        }
    }
}